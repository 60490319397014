import React from 'react'
import loadable from '@loadable/component'
import Layout from '../../components/Layout'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from "gatsby-image"

const Slider = loadable(() => import('../../components/Slider'));
const ConctactCall = loadable(() => import('../../components/ContactCall'));
const Typewriter = loadable(() => import('typewriter-effect'));


export default class Index extends React.Component {

  componentDidMount(){

      var lazyBackgrounds = [].slice.call(
        document.querySelectorAll(".lazy-background")
      );

      if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
      ) {
        let lazyBackgroundObserver = new IntersectionObserver(function (
          entries,
          observer
        ) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              entry.target.classList.add("visible");
              if(entry.target.classList.contains("three")){
                countDown();
              }
              lazyBackgroundObserver.unobserve(entry.target);

            }
          });
        });
        lazyBackgrounds.forEach(function (lazyBackground) {
          lazyBackgroundObserver.observe(lazyBackground);
        });
      }
    ;
    document.getElementById('lang-switch').href = 'https://e-multicontent.com/about-us/'
  }
  render() {
    const { data } = this.props
    return (

  <>
  <Helmet titleTemplate="Dowiedz się kim jesteśmy oraz jak pracujemy! | e-multicontent.pl">
    <title>Dowiedz się kim jesteśmy oraz jak pracujemy! | e-multicontent.pl</title>
    <meta name="description" content={'Na co dzień pracujemy po polsku, ale inne języki nie są nam obce. Pracują dla nas native speakerzy z całego świata. ✔️'}/>
    <meta name='image' content={'https://e-multicontent.pl/img/meta/onas-meta.jpg'} />
    <meta name='keywords' content={'copywriting, copywriterzy, marketing, agencja copywriterska, Wrocław, zespół copywriterów, praca, biznes, treści, content, tłumaczenia, SEO, blog, teksty, artykuły, grafika, video, strony internetowe, sklepy internetowe, marketplace, e-commerce'} />
    <meta property="og:description" content={'Na co dzień pracujemy po polsku, ale inne języki nie są nam obce. Pracują dla nas native speakerzy z całego świata. ✔️'}/>
    <meta property='og:title' content={'Dowiedz się kim jesteśmy oraz jak pracujemy! | e-multicontent.pl'} />
    <meta property='og:image' content={'https://e-multicontent.pl/img/meta/onas-meta.jpg'} />
    <meta property="og:url" content={'https://e-multicontent.pl/o-nas/'}/>

    <link rel="alternate" href="https://e-multicontent.com/about-us/" hrefLang="en-gb" />
    <link rel="alternate" href="https://e-multicontent.com/about-us/" hrefLang="en-us" />
    <link rel="canonical" href="https://e-multicontent.pl/o-nas/"  />

    <meta name="twitter:card" content="summary_large_image" />
    <meta property="twitter:domain" content="e-multicontent.pl" />
    <meta property="twitter:url" content="https://e-multicontent.pl/o-nas/" />
    <meta name="twitter:title" content="Dowiedz się kim jesteśmy oraz jak pracujemy! | e-multicontent.pl" />
    <meta name="twitter:description" content="Na co dzień pracujemy po polsku, ale inne języki nie są nam obce. Pracują dla nas native speakerzy z całego świata. ✔️" />
    <meta name="twitter:image" content="https://e-multicontent.pl/img/apple-touch-icon.png" />
    <script type='application/ld+json'>
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://e-multicontent.pl/"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "O nas",
        "item": "https://e-multicontent.pl/o-nas/"
      }]
    })}
    </script>
  </Helmet>
  <Layout>

      <nav className="breadcrumbs-nav section">
          <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>O nas</b></span>
      </nav>
  <section className="section " style={{padding:'0px',margin:'0px',width:'100%'}}>


  <div
    id="o-nas"
    className="full-width-image-container margin-top-20"
    style={{
      backgroundPosition: `center right`,
      backgroundSize:'cover',
      transform: 'scale(1)',
      height:'509px',
      backgroundRepeat: 'no-repeat',
      paddingTop: '0px',

    }}
  >

    </div>


              <h1
                className="has-text-weight-bold is-size-1"
                style={{
                  color: '',
                  lineHeight: '1',
                  padding: '0.35em',
                  borderRadius:'10px',
                  marginTop: '0%',
                  display: 'block',
                  position: 'absolute',
                  left: '7%',
                  top: '200px',
                  textAlign: 'left',
                  backgroundColor:'rgba(255,255,255,0.7)',



                }}
              >
              O <b style={{color:'#36b3d2'}}>nas</b>
              <br />
              </h1>
              <br />
              <h2
              className="mright"
              style={{
                color: 'rgb(23,19,43)',
                lineHeight: '1',
                padding: '0.35em',
                borderRadius:'10px',
                marginTop: '0%',
                display: 'block',
                position: 'absolute',
                left: '8%',
                top: '350px',
                textAlign: 'left',
                backgroundColor:'rgba(255,255,255,0.8)',}} >Dowiedz się kim jesteśmy oraz jak pracujemy!</h2>

                <p className="ux" align="center" id="nasza-historia"><a className="btnoutline" href="#nasza-historia">⇩</a></p>
                <br />
                <br />
    <div className="container">
      <div className="columns">
        <div className="column">
          <div className="section" style={{marginLeft:'2.5%',width:'95%',marginTop:'-60px'}}>

          <div className="columns">
            <div className="column">
              <h3 style={{fontSize:'1.1em'}}>
              Powiedzieć, że na tworzeniu treści dla handlu elektronicznego zjedliśmy zęby, to nie powiedzieć nic. Od 2013 roku wspieramy branżę e-commerce, tworząc dla niej inspirujące treści. Począwszy od komunikacji marki, przez <Link to="/oferta/copywriting-pl/blogi-dla-e-commerce/" className="dedlink">teksty na bloga </Link>i <Link to="/oferta/copywriting-pl/opisy-produktowe-i-kategorii-w-jezyku-polskim/" className="dedlink">opisy produktów</Link> - te liczymy już w setkach tysięcy. A dobre pióro i przekaz dla klienta zawsze wspieramy wiedzą i narzędziami z zakresu SEO.
              <br /><br />
              Na co dzień pracujemy po polsku, ale inne języki nie są nam obce. Pracują dla nas native speakerzy z całego świata. Poza wysokimi kompetencjami językowymi mają dużą wiedzę marketingową, którą wykorzystują przy tworzeniu treści.
              <br /><br />
              Szacuje się, że polski rynek e-commerce jest warty aż 51 miliardów złotych, a blisko 28 milionów Polaków nie wyobraża sobie innej formy zakupów niż ta online. Nic dziwnego, że w naszym kraju powstaje średnio 21 nowych sklepów internetowych. Każdego dnia! To wszystko sprawia, że Polska jest najbardziej dynamicznie rozwijającym się rynkiem e-commerce w Europie.
              </h3>
              <br />
            </div>

            <div className="column">
              <h3 style={{fontSize:'1.1em'}}>
              Dlatego tak ważne jest, aby wyróżnić się w tym gąszczu biznesów online. A podstawą tego jest dobry i przemyślany content. I tutaj wkraczamy my!
              <br /><br />
              Mimo wieloletniego doświadczenia, wciąż się uczymy i szukamy nowych rozwiązań, a utartym schematom mówimy stanowcze “nie”!
              <br /><br />
              W jakich językach pracujemy? Z uwagi na specyfikę <strong>polskiego e-commerce</strong>, głównie nastawioną na kraje sąsiednie, dużo <strong>treści</strong> przygotowujemy po <strong>czesku, słowacku, rosyjsku, niemiecku i ukraińsku</strong>. Regularnie piszemy po włosku, francusku, hiszpańsku, a także w językach słowiańskich i skandynawskich. Nie boimy się jednak bardziej egzotycznych treści! Potrzebujesz opisów produktów na rynek turecki, japoński, arabski? Zgłoś się do nas, a my z pewnością podołamy nawet takiemu wyzwaniu! </h3>
              <br />
            </div>

          </div>
          <br />
          <hr />

          </div>
        </div>

      </div>

    </div>






{/* Zdjęcie ze strzałkami */}
<div id="arrow-photo" style={{textAlign: 'center',height: 'max-content',width: '100%',justifyContent: 'center'}}>
        <Img fixed={data.file1.childImageSharp.fixed} alt="Zespół e-multicontent - copywriting, tłumaczenia, seo, strony internetowe, e-commerce, marketplace"
        style={{
          position: 'absolute',
          width:'625px',
          zIndex: '-1',
          margin: 'auto'
        }} />



        <div style={{marginTop: '200px'}}>
        Kamil
        <br />
        <small>Office &amp; Content Assistant</small>
        <br />
        <a className="ded-link" style={{fontSize:'12px'}} href="mailto:kamil@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        kamil@e-multicontent.pl
        </a>
        </div>


        <div style={{marginTop: '320px'}}>
        Alicja
        <br />
        <small>PR &amp; Content Manager</small>
        <br />
        <a className="ded-link" style={{fontSize:'12px'}} href="mailto:alicja@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        alicja@e-multicontent.pl
        </a>
        </div>



        <div style={{marginTop: '350px'}}>
        Agnieszka
        <br />
        <small>CEO / Founder </small>
        <br />
        <a className="ded-link" style={{fontSize:'12px'}} href="mailto:agnieszka@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        agnieszka@e-multicontent.pl
        </a>
        </div>



        <div style={{marginTop: '300px',marginRight: '-40px'}}>
        Marta
        <br />
        <small> Content Team Leader </small>
        <br />
        <a className="ded-link" style={{fontSize:'12px'}} href="mailto:marta@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        marta@e-multicontent.pl
        </a>
        </div>

        <div style={{marginTop: '200px'}}>
        Joanna
        <br />
        <small>Translation Project Coordinator </small>
        <br />
        <a className="ded-link" style={{fontSize:'12px'}} href="mailto:joanna@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        joanna@e-multicontent.pl
        </a>
        </div>


</div>

    <div id="team-photo" className="container" style={{marginBottom:'50px',textAlign:'center'}}>
    <Img fluid={data.file2.childImageSharp.fluid}
    alt="Zespół e-multicontent - copywriting, tłumaczenia, seo, strony internetowe, e-commerce, marketplace"
    style={{justifyContent:'center',display:'flex',flexWrap:'wrap',minHeight:'300px'}}
    imgStyle={{
      padding: "0",
      borderRadius: "10px",width:'100%',minHeight:'300px',
      border:'3px solid #f5f5f5',margin:'auto',marginBottom:'50px',
      backgroundColor: 'white',objectFit:'contain',objectPosition:'center'}} />

      <div className="columns" style={{textAlign:'center',marginTop:'25px'}}>
        <div className="column is-4">
        Kamil
        <br />
        <small>Office & Content Assistant</small>
        <br />
        <a className="btn" href="mailto:kamil@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        kamil@e-multicontent.pl
        </a>
        </div>

        <div className="column is-4">
        Alicja
        <br />
        <small>PR & Content Manager</small>
        <br />
        <a className="btn" href="mailto:alicja@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        alicja@e-multicontent.pl
        </a>
        </div>

        <div className="column is-4">
        Agnieszka
        <br />
        <small>CEO / Founder </small>
        <br />
        <a className="btn" href="mailto:agnieszka@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        agnieszka@e-multicontent.pl
        </a>
        </div>

      </div>
      <br />
      <div className="columns" style={{textAlign:'center'}}>
        <div className="column is-6">
        Marta
        <br />
        <small> Content Team Leader </small>
        <br />
        <a className="btn" href="mailto:marta@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        marta@e-multicontent.pl
        </a>
        </div>
        <div className="column is-6">
        Joanna
        <br />
        <small>Translation Project Coordinator </small>
        <br />
        <a className="btn" href="mailto:joanna@e-multicontent.pl" target="_blank" rel="noopener noreferrer">
        joanna@e-multicontent.pl
        </a>
        </div>
      </div>
    </div>
    <br />
    <div className="container">
    <div style={{margin:'0% 3%'}}>
    <h3 className="is-size-2" style={{fontWeight:'800'}}>
    <Typewriter
        onInit={(typewriter) => {
          typewriter.typeString("Poznaj nasz zespół native speakerów i <b style='color:#36b3d2'>dołącz do nas!</b>")
            .pauseFor(2500)
            .deleteAll()
            .start();
        }}
        options={{
          autoStart: true,
          loop: true,
        }}
      />
    </h3>
    <h4 style={{fontSize:'1.2em'}}>W e-multicontent współpracujemy obecnie z grupą ponad 45 native speakerów, władających 35 różnymi językami.<br /> Jeżeli chcesz dołączyć do naszego zespołu to <Link to="/kariera/" className="dedlink"> zapraszamy do kontaktu!</Link></h4>
    </div>
    </div>

    <div className="section">
      <div className="box sliderbox"  style={{ textAlign: 'center',padding:'50px',borderRadius:'20px', backgroundColor: '#36b3d2',color:' #f5f6f5',margin:'auto', marginBottom:'20px'}}  >
      <Slider />
      </div>
    </div>
    <div
      className="full-width-image lazy-background three"
      style={{
        backgroundColor: `#36b3d2`,
        backgroundPosition: `bottom left`,
        backgroundSize:'cover',
        backgroundRepeat:'repeat',
        width: '100%',
        marginBottom:'0px',
        marginTop: '0px',
        height: '100%',
        paddingTop: '50px',
        paddingBottom: '50px'

      }}
    >
    {/*         LICZNIKI         */}
    <div className="columns" style={{textAlign:'center',width:'80%'}}>

      <div style={{height:'20%',backgroundColor:'rgba(255,255,255,0.85)'}} className="column box">
        <br />
        <h2 style={{fontSize:'27px',color:'#29b1ff'}}><b id="t1">10</b></h2>
        <br />
        <h3 style={{color:'#222222'}}><b> lat <br /> w branży</b> </h3>
        <br />
      </div>

      <div style={{height:'20%',backgroundColor:'rgba(255,255,255,0.85)'}} className="column box">
        <br />
        <h2 style={{fontSize:'27px',color:'#29b1ff'}}><b id="t2">250000</b></h2>
        <br />
        <h3 style={{color:'#222222'}}><b> opisów<br /> produktów</b> </h3>
        <br />
      </div>

      <div style={{height:'20%',backgroundColor:'rgba(255,255,255,0.85)'}} className="column box">
        <br />
        <h2 style={{fontSize:'27px',color:'#29b1ff'}}><b id="t3">95000</b></h2>
        <br />
        <h3 style={{color:'#222222'}}><b>ilość<br /> tekstów</b> </h3>
        <br />
      </div>

      <div style={{height:'20%',backgroundColor:'rgba(255,255,255,0.85)'}} className="column box">
        <br />
        <h2 style={{fontSize:'27px',color:'#29b1ff'}}><b id="t4">35</b></h2>
        <br />
        <h3 style={{color:'#222222'}}><b>różnych<br /> języków </b></h3>
        <br />
      </div>

      <br />


    </div>
    </div>


    <ConctactCall />
    {/*
    <div
      className="full-width-image-container lazy-background four"
      loading="lazy"
      style={{
        backgroundColor: `#36b3d2`,
        backgroundPosition: `bottom left`,
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        height:'300px',
        width: '102%',
        marginBottom:'0px',
        marginTop: '0px'

      }}
    ></div>
    */}

</section>
  </Layout>
  </>
)}}

export const query = graphql`
  query File1{
    file1: file(relativePath: {regex: "/optimized/o-nas.png/"}) {
      childImageSharp {
        fixed(width: 625, height: 359) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
          srcWebp
        }
      }
    }
    file2: file(relativePath: {regex: "/optimized/o_nas.jpg/"}) {
      childImageSharp {
        fluid(fit: CONTAIN) {
        srcWebp
        srcSetWebp
        src
        srcSet
      }
      }
    }
    file3: file(relativePath: {regex: "/optimized/kontakt.jpg/"}) {
      childImageSharp {
        fluid(fit: CONTAIN) {
        srcWebp
        src
        srcSet
        srcSetWebp
      }
      }
    }
  }
`




//TIMERS

function countDown() {
//initial numbers
let x = 0;
let x1 = 0;
let x2 = 0;
let x3 = 0;


var a =  setInterval( function(){

    if (document.getElementById('t1')!=null){
        if (x<10){
          x++;
          document.getElementById('t1').innerHTML = x;
        } else if (x===10){
          clearInterval(a);
        }
        }else {
          clearInterval(a);
        }
      }, 1000);


var b =  setInterval( function(){

    if (document.getElementById('t2')!=null){
        if (x1<250000){
          x1+=50;
          document.getElementById('t2').innerHTML = x1;
        } else if (x1===250000){
          clearInterval(b);
        }
        }else {
          clearInterval(b);
        }
      }, 1);

var c =  setInterval( function(){

    if (document.getElementById('t3')!=null){
        if (x2<95000){
          x2+=25;
          document.getElementById('t3').innerHTML = x2;
        } else if (x2===95000){
          clearInterval(c);
        }
        }else {
          clearInterval(c);
        }
      }, 10);

var d =  setInterval( function(){

    if (document.getElementById('t4')!=null){
        if (x3<35){
          x3++;
          document.getElementById('t4').innerHTML = x3;
        } else if (x3===20){
          clearInterval(d);
        }
        }else {
          clearInterval(d);
        }
      }, 500);

}
